import {
  Container,
  Grid,
  Box,
  Typography,
  Button
} from '@mui/material';
import { useSelector } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { useEffect, useState } from 'react';

const ITACCBanner: React.FC = (): JSX.Element => {
  const [showITACCBanner, setShowITACCBanner] =
    useState<boolean>(false);

  const pharmaciesState = useSelector(
    (state: RootStateType) => state.pharmacies
  );

  useEffect(() => {
    const ITACCCounties = [
      'Avery',
      'Buncombe',
      'Burke',
      'Cherokee',
      'Clay',
      'Graham',
      'Haywood',
      'Henderson',
      'Jackson',
      'Macon',
      'Madison',
      'McDowell',
      'Mitchell',
      'Polk',
      'Rutherford',
      'Swain',
      'Transylvania',
      'Yancey'
    ];
    setShowITACCBanner(
      ITACCCounties.some(
        (county) =>
          county.toLowerCase() ===
          pharmaciesState.filters.county
            .trim()
            .toLowerCase()
      )
    );
  }, [pharmaciesState.filters]);

  const navigateToITACC = () => {
    window.open(
      'https://mahec.maps.arcgis.com/apps/webappviewer/index.html?id=a4aa3eb0689f49c4b652066f63e01cd6',
      '_blank'
    );
  };

  return (
    <>
      {showITACCBanner && (
        <Container>
          <Grid container spacing={4}>
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                mb={2}
                sx={{
                  border: '1px solid rgba(0, 0, 0, 0.2)',
                  borderRadius: '8px',
                  boxShadow:
                    '0px 4px 8px rgba(0, 0, 0, 0.1)',
                  transition: 'box-shadow 0.3s ease'
                }}>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    textAlign: 'left',
                    flex: 1,
                    mr: 2
                  }}>
                  Click the ITACC Resource Map link to
                  search for a wider range of healthcare
                  services in your area
                </Typography>

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    minWidth: '15rem',
                    fontSize: '1.2rem',
                    textTransform: 'none'
                  }}
                  onClick={navigateToITACC}>
                  ITACC Resource Map
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
export default ITACCBanner;
