import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const MetaPixel = () => {
  const [loaded, setLoaded] = useState(false);
  const location = useLocation();

  const ENVIRONMENT =
    process.env.REACT_APP_ENVIRONMENT ?? '';
  const META_PIXEL_ID =
    process.env.REACT_APP_META_PIXEL_ID ?? '';

  const trackPageView = () => {
    window.fbq('track', 'PageView');
  };

  useEffect(() => {
    if (ENVIRONMENT === 'DEV') return;

    const script = document.createElement('script');
    script.src = '/meta_pixel.js';
    script.async = true;
    script.onload = () => setLoaded(true);
    script.setAttribute('data-pixel-id', META_PIXEL_ID);
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (loaded) {
      trackPageView();
    }
  }, [location, loaded]);

  return null;
};

export default MetaPixel;
